<template>
  <div class="profile-completed thank-you-page">
    <v-container>
      <v-card class="text-center">
        <CategoryTitle :category="category" />

        <p v-if="fidelityCard">
          {{ $t("profileConfirmed.fidelityCardText") }}
          <span class="font-weight-semibold"> {{ fidelityCard }} </span><br />
          <span>{{ $t("profileConfirmed.additionalText") }}</span>
        </p>
        <!-- <v-card-text class="profile-completed-text">

          <h1>LA TUA REGISTRAZIONE E' CONFERMATA</h1>
          <h3>INIZIA SUBITO LA TUA SPESA!</h3>
         
        </v-card-text> -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";

export default {
  name: "ProfileConfirmed",
  mixins: [categoryMixins],
  components: { CategoryTitle },

  data() {
    return {
      fidelityCard: null
    };
  },
  created() {
    this.fidelityCard = this.$route.params.fidelity;
  }
};
</script>

<style></style>
